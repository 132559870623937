<template>
  <v-container id="regular-tables" fluid tag="section">
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      single-line
      label="Search here"
      placeholder="Search"
      solo
    />
    <v-data-table
      v-model="selected"
      :search="search"
      :headers="headers"
      :items="munCity"
      :items-per-page="10"
      class="elevation-1"
      single-select
      item-key="muncode"
      dense
      flat
    >
      <template v-slot:item.actions="{ item }">
        <v-icon small fab color="info" class="mr-2" @click="viewMoreInfo(item)">
          mdi-information
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Reset </v-btn>
      </template></v-data-table
    >
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "MunCity_List",
  data() {
    return {
      headers: [
        { text: "Code", value: "muncode" },
        { text: "Municipality/ City", value: "munname" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      search: "",
      selected: [],
      munCity: [],
    };
  },
  computed: {
    ...mapGetters(["muncity/munCityData"]),
    ...mapActions(["muncity/getData",'muncity/setSelected']),
  },

  mounted() {
    this.getData();
  },

  methods: {
    async getData() {
      await this["muncity/getData"];
      this.munCity = await this["muncity/munCityData"];
    },

    viewMoreInfo(_item) {
      this.$router.push({ name: 'MunCityDetails'})
      this.$store.dispatch('muncity/setSelected',_item)

    },
  },
};
</script>